import { Component, Vue, Prop } from 'vue-property-decorator'
import http from '@/services/http'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { User } from '@/Models/User'
import IItem from '@/Models/Item'
// import multiSelect from "@/components/multiSelect.vue"

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    // multiSelect,
  },
})
export default class userModal extends Vue {
  @Prop({ required: false })
  userReplace!: User
  @Prop({ required: false })
  action!: string

  loading = false
  color = 'has-background-info'
  iconLeft = 'account-plus'
  confirmationIcon = 'plus'
  confirmationButtonTitle = 'Add user'
  user: User = new User()
  groups: Array<IItem> = []
  // usergroups: IItem[] = []
  usergroups: any = 0
  emit = 'create-user'
  showPass = true
  isLoading = false

  mounted() {
    this.getGroups()
    if (this.action == 'edit') {
      this.showPass = false
      // this.userReplace.groups.forEach((item: any) => {
      // 	this.usergroups.push({
      // 		value: item.id,
      // 		text: item.name,
      // 	})
      // })
      this.usergroups = this.userReplace.groups[0]
      // this.emit = 'edit-user'
      // this.title = 'User edit'
      // this.color = 'has-background-primary'
      // this.iconLeft = 'account-edit'
      // this.confirmationIcon = 'border-color'
      // this.confirmationButtonTitle = 'Edit'
      Object.assign(this.user, this.userReplace)
    } else {
      this.user = new User()
    }
  }
  // updateGroupList(list: Array<any>) {
  // 	this.usergroups = list
  // }
  async getGroups() {
    this.loading = true
    const res = await http.get('api/account/groups/')
    res.data.forEach((element: any) => {
      this.groups.push({
        value: element.id,
        text: element.name,
      })
    })
    this.loading = false
  }

  confirmation() {
    this.isLoading = true
    const group: Array<any> = []
    // this.usergroups.forEach((element) => {
    // 	group.push(element.value)
    // })
    this.user.groups = group

    this.user.groups.push(this.usergroups)
    if (this.action == 'edit') {
      this.updateUsers(this.user)
    } else {
      this.createUsers(this.user)
    }
  }

  async createUsers(user: User) {
    try {
      await http.post('api/panel/users/', user)
      this.$buefy.toast.open({
        message: 'Usuario agregado',
        type: 'is-success',
      })
      this.isLoading = false
      this.close()
    } catch (error) {
      console.error(error)
      this.isLoading = false
      this.$buefy.toast.open({
        message: 'Ha ocurrido un error, intente nuevamente',
        type: 'is-danger',
      })
    }
  }
  async updateUsers(user: User) {
    console.log(user)
    const { photo, ...userUpdate } = user
    try {
      await http.put(`api/panel/users/${userUpdate.id}/`, userUpdate)
      this.$buefy.toast.open({
        message: 'Usuario actualizado',
        type: 'is-success',
      })

      this.isLoading = false
      this.close()
    } catch (error) {
      console.error(error)
      this.isLoading = false
      this.$buefy.toast.open({
        message: 'Ha ocurrido un error, intente nuevamente',
        type: 'is-danger',
      })
    }
  }

  close() {
    this.$emit('closeForm')
  }
}
